var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "info-hint", attrs: { title: _vm.hint } },
    [_c("IconTooltip")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }